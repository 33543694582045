<template>
  <div class="sends">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="msgTitle" placeholder="请输入消息名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-date-picker v-model="datetime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="onSearch" style="border-radius: 10px; background: #f2f2f2; margin-left: 20px; border: none" value-format="yyyy-MM-dd"></el-date-picker>
        <el-button type="primary" @click="handleAdd('add')">群发消息</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column width="100" show-overflow-tooltip prop="number" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="msgTitle" label="消息名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="" label="发送类型">
            <template slot-scope="scope">
              <span v-if="scope.row.receiveRole == 1">供应商</span>
              <span v-if="scope.row.receiveRole == 2">商超</span>
              <span v-if="scope.row.receiveRole == 3">供应商+商超</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdUserName" label="发送人"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="createdTime" label="发送时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="" size="" plain @click="handleAdd('edit', scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      msgTitle: "",
      datetime: [],
      tableData: [],

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      let startTime = ""
      let endTime = ""
      if (this.datetime && this.datetime.length == 2) {
        startTime = this.datetime[0]
        endTime = this.datetime[1]
      }
      this.$axios
        .get(this.$api.selectPlatformMsgList, {
          params: {
            startTime,
            endTime,
            msgTitle: this.msgTitle,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    handleAdd(str, e) {
      if (str == "add") {
        this.$router.push("/customerService/detail/add")
      } else {
        this.$router.push("/customerService/detail/add?id=" + e.id)
      }
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.sends {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
